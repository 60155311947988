import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import { AllImage, GatsbyImageFileWithName, PageContextProps } from '../../utils/graphql';
import Index from '../../components/academy/masterclass/Index';

export const query = graphql`
  query {
    masterImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "masterClass" }
      name: { eq: "greg-hutchings" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      }
    }
    ogImage: file(
      sourceInstanceName: { eq: "images" }
      relativeDirectory: { eq: "masterClass" }
      name: { eq: "greg-hutchings" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    pictures: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "masterClass/pictures/greg-hutchings" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 584, placeholder: BLURRED)
        }
      }
    }
  }
`;

type GregHutchingsCertifiedLessPractitionerFormationQueryProps = {
  masterImage: GatsbyImageFileWithName;
  ogImage: GatsbyImageFileWithName;
  pictures: AllImage;
};

type GregHutchingsCertifiedLessPractitionerFormationProps = PageProps<
  GregHutchingsCertifiedLessPractitionerFormationQueryProps,
  PageContextProps
>;

const GregHutchingsCertifiedLessPractitionerFormation: React.VoidFunctionComponent<
  GregHutchingsCertifiedLessPractitionerFormationProps
> = ({
  data: {
    masterImage,
    ogImage,
    pictures: { nodes: pictures },
  },
  pageContext,
}) => {
  return (
    <Index
      masterclass="greg-hutchings"
      masterImage={masterImage}
      ogImage={ogImage}
      pictures={pictures}
      programFile="PROGRAMME_DE_FORMATION_GREGORY_HUTCHINGS.pdf"
      locale={pageContext.locale}
      closeInscription
    />
  );
};

export default GregHutchingsCertifiedLessPractitionerFormation;
